<!--
 * @Description: 图片空间
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:47:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-07 15:37:11
-->
<template>
  <div>开发中...</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>